import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import ContentWrap from '../atoms/ContentWrap';
import ListItem from '../atoms/contract/ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    marginBottom: 64,
    '& *': {
      fontFamily: theme.typography.fontFamily,
      color: theme.typography.body1.color,
      lineHeight: 1.51,
    },

    '& a': {
      color: theme.palette.info.main,
    },
  },
  hero: {
    height: '550px',
    background: '#F8F9FA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '0px 0px 0px 100px',
    boxShadow: ' 0px 30px 70px 0px #3A35681A',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px',
    },

    '& .MuiTypography-h3': {
      width: '500px',
      textAlign: 'center',
      marginTop: 16,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  bodyContent: {
    marginTop: 140,
  },
  paragraph: {
    '& .MuiTypography-h3': {
      marginBottom: 24,
      marginTop: 24,
    },
    '& .MuiTypography-h4': {
      marginBottom: 12,
      marginTop: 12,
    },
  },
  limitedWidth: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  topic: {
    marginBottom: 16,
  },
  underline: {
    textDecoration: 'underline',
  },
  listLine: {
    marginLeft: '-16px',
    marginBottom: 16,
  },
}));

export default function TermsAndConditions() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.hero}>
        <Typography variant="h6">Termos & Condições</Typography>

        <Typography variant="h3">
          Nós levamos a integridade e a ética muito a sério
        </Typography>
      </div>
      <ContentWrap className={classes.bodyContent}>
        <div>
          <div className={classes.paragraph}>
            <Typography variant="h3">I - Definições</Typography>
            <Typography className={classes.topic} variant="body1">
              1.1. Por Termos de Uso entendem-se os termos e condições previstos
              no presente documento, que esclarecem em caráter prévio a
              prestação de serviços contábeis, administrativos e de assessoria
              tributária, trabalhista e previdenciária pela Conube através de
              Plataforma online própria.
            </Typography>

            <Typography className={classes.topic} variant="body1">
              1.2. Por Conube entendem-se as empresas a seguir qualificadas:
              <ListItem type="lower-roman">
                <li>
                  CONUBE TECNOLOGIA E SERVIÇOS LTDA., sociedade empresária
                  limitada, inscrita no CNPJ/MF sob o n° 21.196.219/0001-88, com
                  sede na Cidade de Taboão da Serra, Estado de São Paulo, na Rua
                  Santa Luzia, nº 579, 3º andar, Vila Santa Luzia, CEP
                  06754-005, com seu ato constitutivo devidamente registrado na
                  Junta Comercial do Estado de São Paulo – JUCESP sob NIRE
                  35228706849, em sessão de 08/10/2014 (“CONUBE TECNOLOGIA”); e
                  {' '}
                </li>
                <li>
                  CONUBE CONTABILIDADE E SERVIÇOS ADMINISTRATIVOS LTDA.,
                  sociedade empresária limitada, inscrita no CNPJ/MF sob o n°
                  18.761.396/0001-55, com sede na Cidade de São Paulo, Estado de
                  São Paulo, na Avenida Engenheiro Luiz Carlos Berrini, n° 1140,
                  7º andar, sala 707, Cidade Monções, CEP 04571-000, com seu ato
                  constitutivo devidamente registrado na Junta Comercial do
                  Estado de São Paulo – JUCESP sob NIRE 35227782720, em sessão
                  de 26/08/2013 (“CONUBE CONTABILIDADE”).
                </li>
              </ListItem>
            </Typography>

            <Typography className={classes.topic} variant="body1">
              1.3. A CONUBE CONTABILIDADE oferece ao Cliente os serviços
              contábeis, administrativos e de assessoria tributária, trabalhista
              e previdenciária, de forma rápida e utilizando-se de sistema cujas
              funcionalidades e recursos são disponibilizados pela CONUBE
              TECNOLOGIA em uma plataforma online no website de sua propriedade
              {' '}
              <a href="https://www.conube.com.br" target="_blank" rel="noreferrer">conube.com.br</a>
              {' '}
              (“Plataforma”);
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.4. Por Cliente, entende-se o cliente final, pessoa física ou
              jurídica, que tenha:
              <ListItem type="lower-roman">
                <li>capacidade jurídica para a prática dos atos civis;</li>
                <li>um hardware compatível, softwares compatíveis e acesso à internet;</li>
                <li>
                  que venha a contratar os serviços da Conube que serão prestados
                  através da plataforma online;
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.5. Por Partes entendem-se Conube e Cliente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.6. Por Terceiros entendem-se quaisquer pessoas
              físicas ou jurídicas que não integrem a relação jurídica a ser
              formalizada entre as Partes.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.7. Por Serviços entendem-se todos os serviços
              contábeis, administrativos e de assessoria tributária, trabalhista
              e previdenciária oferecidos pela Conube através da Plataforma, os
              quais serão especificados em cláusula própria.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.8. Por Contrato de Prestação de Serviços entende-se o
              contrato de prestação de serviços que será celebrado
              entre a Conube e o Cliente se este realizar o cadastro e aceitar,
              mediante Aceite Inicial a seguir definido, as condições previstas
              para utilização da Plataforma e prestação dos Serviços.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.9. Por Contratação entende-se a transação por meio da qual um
              Cliente contrata um ou mais Serviços oferecidos pela Conube
              através da Plataforma, mediante o Aceite Inicial e posterior
              celebração do Contrato de Prestação de Serviços.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.10. Por Cadastro entende-se a credencial definida pelo e-mail do
              Cliente e senha cadastrada, pessoal e intransferível que permite
              acesso à área restrita e às funcionalidades exclusivas da
              Plataforma, tais como acesso e alteração de dados pessoais e de
              todas as informações contábeis, fiscais e de folha de pagamento
              relativas aos Serviços contratados.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.11. Por Informações entendem-se todas e quaisquer informações
              postadas pelo Cliente, sobre si e sua empresa, ou que o Cliente
              dê, direta ou indiretamente, no registro na Plataforma ou do uso
              da Plataforma, assim como das mensagens enviadas dentro da
              Plataforma, ou informações fornecidas através de contato
              telefônico com a central de atendimento.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.12. Por Política de Privacidade entendem-se os termos e
              condições aplicáveis às Informações e dados relacionados à
              prestação do Serviços, que podem ser encontrados no website:
              {' '}
              <a href="https://www.conube.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">conube.com.br/politica-de-privacidade.</a>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.13. Por Plataforma entendem-se por site de relacionamento com
              Cliente, de uso obrigatório na prestação de serviços da Conube, e
              de troca de informações, dados e documentos relacionados a
              prestação de serviços indicadas neste documento.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              1.14. Por Aceite Inicial entende-se a confirmação prévia de
              ciência e concordância com os Termos de Uso e a Política de
              Privacidade, dada por um dos representantes ou sócios do Cliente,
              que gera a obrigação de assinatura do Contrato de Prestação de
              Serviços, visto que pode ocorrer antes da existência legal do
              Cliente quando pessoa jurídica.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">II - Objeto</Typography>
            <Typography className={classes.topic} variant="body1">
              2.1. Os presentes Termos de Uso esclarecem as regras que constarão
              no Contrato de Prestação de Serviços que poderá ser celebrado
              entre o Cliente e a Conube, para os fins de utilização da
              Plataforma, a qual possibilita a utilização dos Serviços definidos
              nestes Termos de Uso.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              2.2. Estes Termos de Uso não criam uma relação jurídica entre o
              Cliente e a Conube, configurando apenas um informativo dos
              Serviços prestados e das regras aplicáveis. Assim, caso o Cliente
              concorde com os Termos de Uso e deseje contatar os Serviços
              fornecidos pela Conube, deverá preencher os campos necessários ao
              Cadastro com Informações válidas e verdadeiras, dar o Aceite
              Inicial, receber a confirmação do Cadastro através de e-mail, e
              celebrar o Contrato de Prestação de Serviços.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              2.3. O Aceite Inicial e a consequente celebração do Contrato de
              Prestação de Serviços após realização de Cadastro junto à
              Plataforma implicarão no entendimento e na aceitação total das
              condições, regras e normas descritas nos Termos de Uso, que
              estarão contempladas no Contrato de Prestação de Serviços, mas que
              deverão ser lidas e aceitas, vigorando o Contrato de Prestação de
              Serviços em eventuais casos de contradição.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              2.4. Todos os direitos não previstos expressamente nestes Termos
              de Uso são reservados à Conube.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">III - Serviços</Typography>
            <Typography className={classes.topic} variant="body1">
              3.1. Os Serviços ofertados pela Conube são os seguintes:
              <ListItem type="lower-roman">
                <li>
                  Serviço de abertura de empresas (“Serviços de Abertura”);
                </li>
                <li>
                  Serviço de alteração societária (“Serviços de Alteração”);
                </li>
                <li>
                  Serviços mensais contábeis, assessoria tributária e
                  trabalhista (“Serviços Mensais”);
                  {' '}
                </li>
                <li>
                  Serviços avulsos “outros” relacionados de forma direta ou
                  indireta aos serviços mencionados anteriormente (“Serviços
                  Avulsos”).
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              3.2. Todos os Serviços serão realizados através da Plataforma,
              mediante a utilização do Cadastro realizado, e a comunicação entre
              o Cliente e a Conube ocorrerá conforme o plano contratado,
              observado o que segue:
              <ListItem type="lower-roman">
                <li>
                  Online: atendimento somente via chat (logado na plataforma) ou
                  pelo e-mail: contador@conube.com.br.
                </li>
                <li>
                  Private: atendimento via chat (logado na plataforma),
                  whatsapp, telefone, e pelo e-mail: private@conube.com.br.
                </li>
                <li>
                  Corporate: atendimento via chat (logado na plataforma),
                  whatsapp, telefone, e pelo e-mail: corporate@conube.com.br,
                  com consultor especialista dedicado.
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              3.3. Independentemente da Contratação, o horário de atendimento da
              Conube é de segunda a sexta-feira, das 9 (nove) às 18 (dezoito)
              horas (exceto feriados).
            </Typography>
            <Typography className={classes.topic} variant="body1">
              3.4. A Conube, a seu exclusivo critério, reserva-se o direito de
              não fornecer o acesso à Plataforma em determinadas regiões do
              Brasil, bem como a pessoas com características que tragam
              obrigações específicas, de acordo com o previsto em
              {' '}
              <a href="https://www.conube.com.br/condicoes-de-impedimentos" target="_blank" rel="noreferrer">conube.com.br/condicoes-de-impedimentos</a>
              <ListItem type="lower-alpha">
                <li>
                  Uma vez verificada qualquer descumprimento das condições
                  impeditivas, a Conube poderá rescindir o Contrato de Prestação
                  de Serviços, independente de notificação.
                  {' '}
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              3.5. Não estão contemplados os seguintes serviços, que podem ser
              contratados à parte mediante solicitação pela Plataforma e/ou
              através do e-mail de contato, conforme plano contratado
              (contador@conube.com.br, ou private@conube.com.br ou
              corporate@conube.com.br):
              <ListItem type="lower-roman">
                <li>
                  Portador para entrega e retirada de eventuais documentos
                  físicos que possam ser solicitados pela Conube, seja qual for
                  a natureza;
                </li>
                <li>
                  Certificado digital para o Cliente ou seus representantes,
                  sucessores, empregados ou representantes;
                </li>
                <li>
                  Emissão mensal / trimestral / semestral de balancetes
                  contábeis em periodicidade que não esteja no plano contratado;
                </li>
                <li>
                  Emissão mensal / trimestral / semestral de balanços e
                  demonstrativo de resultado em periodicidade que não esteja no
                  plano contratado;
                </li>
                <li>
                  Emissão de certidões negativas de débitos de qualquer
                  natureza;
                </li>
                <li>
                  Entrega de obrigações acessórias anuais como DIMOB, COAF, SST
                  ESocial;
                </li>
                <li>Elaboração e gestão dos dados para emissão da DIMOB;</li>
                <li>
                  Declaração de Imposto de Renda da Pessoa Física – DIRPF;
                </li>
                <li>
                  Declaração Comprobatória de Percepção de Rendimentos - DECORE;
                </li>
                <li>Declaração de faturamento;</li>
                <li>
                  Acompanhamento ou realização de auditorias e diligências;
                </li>
                <li>Elaboração e/ou revisão de planejamento tributário;</li>
                <li>Controle e gestão de benefícios de empregados;</li>
                <li>Ferramenta para controle de ponto de empregados;</li>
                <li>Gestão de ponto de empregados;</li>
                <li>Emissão e controle de contratos com colaboradores;</li>
                <li>
                  Emissão e controle de contratos com terceiro (fornecedores e
                  clientes);
                </li>
                <li>
                  Levantamento e apuração de pendências fiscais e trabalhistas
                  (incluindo de períodos anterior a responsabilidade da Conube);
                </li>
                <li>
                  Reprocesso e/ou regularização de pendências fiscais,
                  trabalhistas e contábeis (incluindo de períodos anterior a
                  responsabilidade da Conube);
                  {' '}
                </li>
                <li>Endereço fiscal da pessoa jurídica;</li>
                <li>
                  Emissão, acompanhamento e renovação do alvará de
                  funcionamento, bem como a documentação necessária para sua
                  obtenção;
                </li>
                <li>
                  Emissão, acompanhamento, renovação e/ou regularização de
                  Habite-se do endereço da pessoa jurídica;
                </li>
                <li>
                  Emissão, acompanhamento, renovação e/ou regularização do Laudo
                  Técnico da Vigilância Sanitária;
                </li>
                <li>
                  Acompanhamento, renovação e/ou regularização de vistorias e
                  avaliações do Corpo de Bombeiros e demais órgãos;
                </li>
                <li>
                  Registro do Cliente ou seus representantes em órgãos de
                  classe, como CRECI, CORECON, CREA, CRM, CRO, SUSEP, OAB, CRC,
                  CFP, COFFITO, entre outros;
                </li>
                <li>
                  Emissão e/ou Obtenção e pagamento de taxas para registro da
                  pessoa jurídica ou física em órgãos de classe;
                </li>
                <li>
                  Emissão e/ou pagamento de taxa de fiscalização de
                  estabelecimento / licenciamento / funcionamento cobrada por
                  Prefeituras;
                </li>
                <li>
                  Emissão e/ou preparação de documentos especiais para processos
                  de licitação, bem como seu acompanhamento;
                </li>
                <li>
                  Custas e/ou pagamento de cobranças de cartórios de qualquer
                  natureza;
                </li>
                <li>Custas de taxas para abertura de empresa;</li>
                <li>
                  Encerramento empresarial / societário (honorários e taxas);
                </li>
                <li>Emissão de nota fiscal de serviço;</li>
                <li>Emissão de nota fiscal de venda de produto;</li>
                <li>
                  Categorização fiscal de produtos para venda (até 5 itens por
                  mês está incluso nos honorários);
                </li>
                <li>
                  Recálculo e/ou reprocessamento de dados gerados pela Conube
                  (quando ocasionado por atraso ou falha do cliente);
                </li>
                <li>
                  Gestão financeira, operacional administrativo, faturamento,
                  tesouraria, financeiro e/ou controladoria, bem como custas de
                  sistemas e plataformas destas naturezas;
                </li>
                <li>Reorganização societária;</li>
                <li>
                  Estudo e/ou opinião formal de novos negócios e parcerias;
                </li>
                <li>Participação em reuniões de assembleias;</li>
                <li>
                  Parcelamento tributário e previdenciário de qualquer natureza
                  – análise, pedido e emissão de guias;
                </li>
                <li>Notas explicativas das demonstrações financeiras;</li>
                <li>
                  Acompanhamento de homologação e/ou acordo de rescisões
                  trabalhistas (judiciário e/ou outros locais regulados);
                </li>
                <li>Autenticação / registro de livros comerciais e fiscais;</li>
                <li>
                  Guarda e/ou manutenção de documentação física e eletrônica de
                  qualquer natureza de propriedade do Cliente;
                  {' '}
                </li>
                <li>
                  Novas funcionalidades da plataforma poderão ser cobradas em
                  adicional, caso seja interesse do cliente.
                </li>
                <li>Folha de pagamento de doméstico;</li>
                <li>
                  Atendimento e preenchimento do formulário de pesquisa do IBGE;
                </li>
                <li>
                  Emissão e homologação de memorando de acordo de PLR junto aos
                  órgãos competentes
                </li>
              </ListItem>
            </Typography>

            <div className={classes.paragraph}>
              <Typography variant="h4">
                A) Serviço de abertura de empresas
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.6. São classificados como “serviços avulsos”, e devem ser
                contratados a parte quando ocorrerem. O valor da remuneração do
                serviço de abertura de empresa seguirá o expresso na tabela de
                preços – “grupo: serviços avulsos de abertura
                {' '}
                <a href="https://www.conube.com.br/precos" target="_blank" rel="noreferrer">www.conube.com.br/precos</a>
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.7. Os Serviços de Abertura abrangem:
              </Typography>

              <Typography className={classes.topic} variant="body1">
                CONSULTA EM ÓRGÃOS PÚBLICOS
                <ListItem type="lower-roman">
                  <li>
                    Realização da consulta prévia de viabilidade na Prefeitura
                    da localidade indicada pelo Cliente
                  </li>
                  <li>
                    Realização da consulta de viabilidade de nome na Junta
                    Comercial da localidade indicada pelo Cliente;
                  </li>
                  <div className={classes.listLine}>
                    ELABORAÇÃO DOS DOCUMENTOS NECESSÁRIOS
                  </div>
                  <li>
                    Contrato Social para Sociedades, Sociedades Limitadas de
                    Único Sócio, ou Empresas Individuais de Responsabilidade
                    Limitada;
                  </li>
                  <li>
                    Documento Básico de Entrada (DBE) para registro na Receita
                    Federal (CNPJ);
                  </li>
                  <li>
                    Demais documentos obrigatórios para o registro na Junta
                    Comercial do Estado;
                  </li>
                  <li>
                    Registro na Prefeitura - inscrição no CCM (com exceção de
                    São Paulo, as outras cidades requerem apoio adicional por
                    parte do Cliente);
                  </li>
                  <li>
                    Solicitação de Enquadramento no Simples Nacional (válido
                    apenas para empresas que possuem as qualificações permitidas
                    ao enquadramento);
                  </li>
                  <li>
                    Registro na Caixa Econômica Federal (somente com certificado
                    digital);
                  </li>
                  <li>Enquadramento no sindicato de classe;</li>
                  <li>
                    Apoio na emissão do certificado digital e-CNPJ do tipo A1.
                  </li>
                </ListItem>
              </Typography>

              <Typography className={classes.topic} variant="body1">
                3.8. O prazo de execução dos Serviços de Abertura varia conforme
                a localidade e disponibilidade do órgão público. O início da
                prestação dos Serviços pela Conube ocorre somente quando da
                confirmação do pagamento e a entrega dos documentos necessários
                e dos formulários devidamente assinados.
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.9. O Cliente é responsável pela indicação de atividades
                descritas no Cadastro Nacional de Atividades Econômicas – CNAE,
                assumindo toda e qualquer responsabilidade na descrição de suas
                próprias atividades, inclusive necessidade de registro junto a
                órgãos representativos de classe.
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.10. A Conube envidará seus melhores esforços na rápida
                prestação dos Serviços de Abertura, contudo, por depender de
                aprovações da Junta Comercial, Prefeitura e/ou outros órgãos, a
                Conube não se responsabiliza pelos prazos utilizados por tais
                órgãos para aprovação da abertura e funcionamento da empresa.
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.11. Ao contratar os Serviços de Abertura, o Cliente
                compromete-se a não exercer qualquer atividade empresarial antes
                da conclusão do registro, conforme exige a legislação
                brasileira.
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.12. A Conube não se responsabiliza por quaisquer investimentos
                ou custos obtidos pelo Cliente para a realização da atividade
                antes da conclusão e aprovação do registro empresarial, tampouco
                por quaisquer perdas e danos diretos ou indiretos, lucros
                cessantes, reclamações de Terceiros ou qualquer fato fora de seu
                controle razoável.
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.13. Ao receber todas as Informações referentes à abertura de
                empresas, o Cliente compromete-se a realizar o protocolo nos
                órgãos públicos correspondentes, de acordo com orientações da
                Conube, no prazo máximo de 30 (trinta) dias.
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.14. Ao solicitar os Serviços de Abertura, o Cliente se declara
                ciente acerca da necessidade de atender a todos requisitos
                legais para abertura de empresa no que diz respeito à atividade
                de sua empresa. Portanto, compromete-se a atender a todas as
                exigências sempre que necessário e solicitado por quaisquer
                órgãos públicos, bem como a ter um sistema de escrituração
                contábil.
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.15. A Contratação Conjunta contempla a prestação dos Serviços
                de Abertura combinada com a prestação dos Serviços Mensais, nos
                exatos termos apresentados e aceitos no processo de abertura.
                Caso o serviço mensal não seja contratado, o valor da
                remuneração do serviço de abertura seguirá o expresso na tabela
                de preços – “grupo: serviços avulsos de abertura
                (
                <a href="https://www.conube.com.br/precos" target="_blank" rel="noreferrer">www.conube.com.br/precos</a>
                ).
              </Typography>
            </div>

            <div className={classes.paragraph}>
              <Typography variant="h4">
                B) Serviços de alteração contratual
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.16. São classificados como “serviços avulsos”, e devem ser
                contratados a parte quando ocorrerem. O valor da remuneração do
                serviço de alteração societária seguirá o expresso na tabela de
                preços – grupo: serviços avulsos de alteração
                {' '}
                <a href="https://www.conube.com.br/precos" target="_blank" rel="noreferrer">www.conube.com.br/precos</a>
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.17. Os Serviços de Alteração compreendem:
                <ListItem type="lower-roman">
                  <div className={classes.listLine}>
                    CONSULTA EM ÓRGÃOS PÚBLICOS
                  </div>
                  <li>
                    Realização da consulta prévia de viabilidade na Prefeitura –
                    se aplicável;
                  </li>
                  <li>
                    Realização da consulta de viabilidade de nome na Junta
                    Comercial – se aplicável;
                  </li>
                  <div className={classes.listLine}>
                    ELABORAÇÃO DOS DOCUMENTOS NECESSÁRIOS
                  </div>
                  <li>
                    Alteração de Contrato Social para Sociedades Limitadas ou
                    Empresas Individuais de Responsabilidade Limitada;
                  </li>
                  <li>
                    Alterações de Empresário para empresas de natureza jurídica
                    Empresário Individual;
                  </li>
                  <li>
                    Documento Básico de Entrada (DBE) na Receita Federal – se
                    aplicável;
                  </li>
                  <li>
                    Demais documentos obrigatórios para o registro na Junta
                    Comercial e Prefeitura;
                  </li>
                  <li>
                    Solicitação de Enquadramento no Simples Nacional (válido
                    apenas para empresas que possuem atividades permitidas ao
                    enquadramento), Lucro Real e Lucro Presumido.
                  </li>
                </ListItem>
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.18. O prazo de execução dos Serviços de Alteração Societária
                varia conforme a localidade e disponibilidade do órgão público.
                O início da prestação dos Serviços pela Conube ocorre somente
                quando da confirmação do pagamento e a entrega dos documentos
                necessários e dos formulários devidamente assinados.
              </Typography>
            </div>

            <div className={classes.paragraph}>
              <Typography variant="h4">
                C) Serviços mensais, contábeis, assessoria tributária e
                trabalhista
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.19. Os Serviços Mensais contemplam os seguintes, dentre
                outros:
              </Typography>
              <Typography>
                <ListItem type="lower-roman">
                  <li>
                    Área contábil:
                    <ListItem type="lower-alpha">
                      <li>
                        Prestação de serviços contábeis, nos termos do artigo 25
                        do Decreto Lei 9.295/46, combinando com os itens 01 ao
                        48 (exceto 34 e 44) do artigo 3° da resolução do CFC
                        560/83;
                        {' '}
                      </li>
                      <li>
                        Classificação e escrituração da contabilidade de acordo
                        com as normas e princípios contábeis vigentes;
                      </li>
                      <li>
                        Elaboração do Balanço e Demonstrações do Resultado do
                        Exercício, base anual, conforme normas e princípios
                        contábeis vigentes;
                      </li>
                      <li>
                        Emissão mensal de balancetes (no caso do plano de
                        atendimento “online” a frequência é anual);
                      </li>
                      <li>Emissão anual dos livros diários e razão;</li>
                      <li>
                        Conciliação dos saldos contábeis (no caso do plano de
                        atendimento “online” a frequência é anual);
                      </li>
                      <li>
                        Controle de obrigações acessórias anuais: Sped ECD e
                        DEFIS;
                      </li>
                    </ListItem>
                  </li>
                  <li>
                    Área Fiscal:
                    <ListItem type="lower-alpha">
                      <li>
                        Orientação e controle da aplicação dos dispositivos
                        legais vigentes (federais, estaduais ou municipais) e
                        para emissão de nota fiscal serviços e produtos;
                      </li>
                      <li>
                        Apuração mensal dos impostos sobre faturamento (simples
                        nacional, lucro presumido e lucro real);
                      </li>
                      <li>
                        Apuração mensal das retenções de impostos sobre serviços
                        tomados;
                      </li>
                      <li>
                        Envio das obrigações acessórias mensais: Sped ECF, DCTF,
                        DCTF Web, EFD-Contribuições, PGDAS, GIA-ICMS, DeSTDA;
                        {' '}
                      </li>
                      <li>
                        Envio das obrigações acessórias anuais: Sped ECF, DEFIS,
                        DIRF, DMED
                        {' '}
                      </li>
                      <li>
                        Atendimento das demais exigências previstas em atos
                        normativos, bem como de eventuais procedimentos de
                        fiscalização.
                      </li>
                    </ListItem>
                  </li>
                  <li>
                    Área trabalhista:
                    <ListItem type="lower-alpha">
                      <li>
                        Orientação e controle da aplicação dos preceitos da
                        Consolidação das Leis do Trabalho, bem como aqueles
                        atinentes à Previdência Social, PIS, FGTS e outros
                        aplicáveis às relações de emprego mantidas pelo Cliente
                        (pessoa jurídica);
                      </li>
                      <li>
                        Manutenção dos registros de empregados, incluindo
                        admissões e desligamentos;
                      </li>
                      <li>
                        Elaboração da folha mensal de pagamento de dos
                        administradores (sócios e não sócios);
                      </li>
                      <li>
                        Elaboração da folha mensal e quinzenal de pagamento dos
                        empregados;
                      </li>
                      <li>
                        Emissão dos relatórios de provisão de férias e de 13º
                        salário;
                      </li>
                      <li>
                        Emissão das guias de recolhimento dos encargos sociais e
                        e previdenciários sobre a folha (INSS, FGTS, PIS e
                        IRRF);
                      </li>
                      <li>Gerenciamento de férias e afastamentos;</li>
                      <li>
                        Envio das obrigações acessórias mensais: GFIP/SEFIP,
                        DCTF Web, E-Social;
                      </li>
                      <li>
                        Envio das obrigações acessórias anuais: DIRF e RAIS (sem
                        movimento);
                      </li>
                      <li>
                        Relacionamento com sindicatos (enquadramento e guia
                        sindical)
                        {' '}
                      </li>
                    </ListItem>
                  </li>
                  <li>
                    Outros serviços como: acesso à Plataforma, emissor de nota
                    fiscal de serviços (para as cidades habilitadas), conteúdo
                    informacional, atendimento online (via chat e e-mail) e por
                    telefone.
                  </li>
                </ListItem>
              </Typography>
              <Typography className={classes.topic} variant="body1">
                3.20. Para os serviços mensais os seguintes prazos deverão ser
                observados, que a Conube não tenha atraso nas entregas em tempo
                hábil:
                <ListItem type="lower-roman">
                  <li>
                    Envio até o 5º dia útil do mês seguinte da movimentação
                    mensal financeira, via Plataforma, para apuração fiscal e
                    contábil.
                    {' '}
                  </li>
                  <li>
                    Fechamento e conclusão até o 1º dia útil do mês seguinte do
                    faturamento (receita bruta), para apuração fiscal.
                  </li>
                  <li>
                    Comunicação e envio de dados de admissões de empregados com
                    antecedência de 6 dias início do trabalho.
                    {' '}
                  </li>
                  <li>
                    Comunicação e envio de dados de rescisão de empregados com
                    antecedência de 6 dias do desligamento.
                  </li>
                  <li>
                    Comunicação e envio do pedido de férias de empregados com
                    antecedência de 10 dias início do período de gozo.
                  </li>
                </ListItem>
              </Typography>
            </div>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">
              IV - Remuneração e forma de pagamento
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.1. Mediante o Aceite Prévio e consequente assinatura do Contrato
              de Prestação de Serviços, o Cliente pagará para a Conube, à título
              de remuneração pelos Serviços contratados (“Remuneração”), os
              valores conforme Tabela de Preços e o plano de atendimento
              escolhido.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.2. A Remuneração dos Serviços Mensais pode variar conforme:
              <ListItem type="lower-roman">
                <li>forma de pagamento mensal, semestral ou anual;</li>
                <li>atividade de prestação de serviços e/ou comércio;</li>
                <li>regime tributário;</li>
                <li>quantidade de sócios;</li>
                <li>quantidade de colaboradores;</li>
                <li>faturamento</li>
                <li>
                  número de filiais, dentre outros. A alteração
                  varia conforme a Tabela de Preços
                  {' '}
                  <a href="https://www.conube.com.br/precos" target="_blank" rel="noreferrer">www.conube.com.br/precos</a>
                  , e sua
                  atualização será aplicada após comunicação prévia.
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.3. As Tabelas de Preços anteriores e atuais podem ser
              solicitadas pelo Cliente à Conube pelos canais oficiais de
              atendimento.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.4. No caso de contratação do plano online, a consulta técnica
              via atendimento telefônico é um recurso com uma franquia mensal
              máxima de até 15 (quinze) minutos, não sendo este valor cumulativo
              entre os meses e considerando que o somatório de atendimentos em
              um mês que ultrapasse os 15 (quinze) minutos supracitados
              resultarão na cobrança pela Conube junto ao Cliente do valor
              cumulativo de R$ 80,00 (oitenta reais) por hora, sendo estipulado
              o tempo mínimo de cobrança de 30 (trinta) minutos, com valor
              proporcional.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.5. Todas as taxas decorrentes da prestação dos Serviços são de
              responsabilidade do Cliente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.6. No caso de reprocessamento da apuração mensal fiscal, folha
              de pagamentos e contabilização, seja por solicitação de correção
              ou inclusão por parte da CONTRATANTE, será cobrado honorário
              adicional de 50% do valor mensal cobrado no mês, referente à área
              contábil e fiscal;
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.7. A Remuneração foi estabelecida com base nas informações, nos
              volumes operacionais, na complexidade dos processos e no modelo
              atual dos Serviços, os quais foram definidos e/ou aprovados pelo
              Cliente e que possibilitou estimar o tempo a ser despendido na
              execução dos Serviços, de acordo com o nível técnico requerido dos
              profissionais envolvidos.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.8. Como se trata de estimativa do volume atual de documentos que
              serão escriturados e entendimento preliminar das operações, a
              Remuneração proposta será revista à medida que o volume e
              complexidade das operações do Cliente sejam conhecidos, ou seja,
              diferente das informações estimadas. Além disso, os valores base
              serão revisados de forma anual e serão informados previamente ao
              Cliente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.9. O Cliente realizará, conforme “Data de Pagamento” indicada no
              Contrato de Prestação de Serviços, o respectivo pagamento da
              Remuneração e todas as taxas e impostos sobre vendas e quaisquer
              outras despesas aplicáveis à prestação dos Serviços.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.10. A Remuneração pelos Serviços Mensais ou Contratação Conjunta
              será cobrada mensalmente, com início no primeiro mês de
              responsabilidade da Conube perante a empresa do Cliente. No caso
              de Serviços de Abertura é no mês em que o CNPJ foi emitido. No
              caso de troca de contador é no mês que se iniciou a
              responsabilidade da Conube pela prestação de Serviços Mensais à
              empresa.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.11. A cobrança será efetuada por comunicação via e-mail e o
              pagamento poderá ocorrer via boleto bancário, cartão de crédito,
              conforme expresso pela Conube. A cobrança ocorrerá a partir da
              data de abertura da empresa (mês indicado no cartão do CNPJ) ou da
              data de início de responsabilidade, em caso de migração (indicada
              no termo de transferência de responsabilidade técnica e/ou e-mail)
              (“Pagamento da Remuneração”).
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.12. O grupo Conube é formado pelas empresas CONUBE TECNOLOGIA e
              CONUBE CONTABILIDADE, sendo que cada uma receberá valor
              proporcional ao serviço prestado e ambas emitirão notas fiscais
              correspondentes aos seus serviços.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.13. O recibo ou comprovante do pagamento pelo Cartão de Crédito
              / Boleto Bancário, servirá como recibo e comprovante da quitação
              da Remuneração efetivamente paga, a cada mês.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.14. O atraso no pagamento da Remuneração, por culpa exclusiva do
              Cliente, implicará no pagamento de multa correspondente a 2% (dois
              por cento) do valor em atraso, atualizado pela variação do IGP-M,
              acrescido de juros de 1% (um por cento) ao mês “pro rata
              temporis”, desde a data do inadimplemento até a data de seu
              efetivo pagamento.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.15. O atraso de 60 (sessenta) dias no pagamento de qualquer
              Remuneração gera o bloqueio de acesso do Cliente à Plataforma, sem
              prejuízo da apuração das penalidades previstas no Contrato de
              Prestação de Serviços. Não serão de responsabilidade da Conube os
              prejuízos incorridos pelo não acesso às informações, dados,
              documentos, guias, entre outros.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.16. O atraso de 90 (noventa) dias no pagamento de qualquer
              Remuneração gera a suspensão da prestação dos Serviços. Não serão
              de responsabilidade da Conube os prejuízos incorridos pela não
              prestação dos Serviços.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.17. O acesso à Plataforma somente será restabelecido após a
              identificação pela Conube do pagamento integral de todos os
              valores devidos enquanto esteve suspenso. A identificação poderá
              ocorrer em até dois dias úteis após a data de pagamento pelo
              Cliente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.18. A Remuneração será reajustada anualmente pelo mesmo índice
              utilizado para o reajuste anual definido pelo Sindicato dos
              Empregados de Agentes Autônomos do Comércio e em Empresas de
              Assessoramento, Perícias, informações e pesquisas e de Empresas de
              Serviços Contábeis no Estado de São Paulo correspondente à
              categoria profissional da Conube. O reajuste tem como objetivo
              assegurar o equilíbrio econômico e financeiro do Contrato de
              Prestação de Serviços, razão pela qual ele ocorrerá de forma
              automática, sendo informado ao Cliente com 30 dias de
              antecedência.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.19. As despesas diretamente vinculadas à execução dos Serviços,
              tais como comunicação, reproduções, mensageiros, cartório,
              postais, transporte, refeições, livros fiscais, taxas etc., serão
              objeto de pedido de reembolso, ao valor de custo, que serão
              acrescidos ao faturamento mensal.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.20. A Remuneração é calculada contemplando os impostos locais
              incidentes sobre o faturamento da Conube, que atualmente adota o
              regime Lucro Real. No caso de incidência futura de qualquer outro
              tipo de tributo, que incida ou venha a incidir sobre a prestação
              de Serviços, seja nova incidência ou aumento das alíquotas
              existentes, tais impostos e/ou acréscimos serão integralmente
              acrescidos à Remuneração.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.21. A Conube poderá recusar um pedido de reembolso caso encontre
              uma prova de fraude, abuso de reembolso, ou outro comportamento
              manipulativo que dê direito a uma reconvenção.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.22. Caso a empresa do Cliente fique inativa por culpa exclusiva
              do Cliente, a Conube reserva-se o direito de seguir com a
              contratação dos Serviços Mensais, bem como a manter a cobrança de
              Remuneração, eis que são mantidas algumas obrigações da empresa
              perante os órgãos municipais, estaduais e federais e a Conube
              continuará a prestar ao Cliente os Serviços para manter a empresa
              regular.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              4.23. valor da Remuneração será reajustado caso:
              <ListItem type="lower-roman">
                <li>
                  ocorra
                  alteração de qualquer condição relativa ao Cliente ou sua empresa,
                  tais como, mas não se limitando, alteração de atividades, de
                  endereço, de quantidade de sócios, de quantidade de empregados,
                  regime tributário, faturamento etc.;
                </li>
                <li>
                  ocorra alteração nos
                  Serviços, seja para ampliar ou reduzir o escopo do Contrato de
                  Prestação de Serviços;
                </li>
                <li>
                  ocorra prorrogação do Contrato de
                  Prestação de Serviços ou nova contratação, visto que o Contrato de
                  Prestação de Serviços possui prazo certo e determinado.
                </li>
              </ListItem>
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">V - Propriedade Intelectual</Typography>
            <Typography className={classes.topic} variant="body1">
              5.1. O Cliente não adquire, pelo Contrato de Prestação de Serviços
              ou pela utilização da Plataforma, nenhum direito sobre ou
              relacionado à Plataforma, nem direitos de propriedade intelectual
              ou outros direitos exclusivos, incluindo, mas não se limitando,
              textos, gráficos, imagens, logotipos, ícones, fotografias,
              conteúdo editorial, notificações, softwares, arte, layout, nomes,
              patentes, desenhos, marcas, direitos autorais ou quaisquer
              direitos sobre informações confidenciais ou segredos de negócio,
              bem como todo o conteúdo disponibilizado na Plataforma e qualquer
              outro material, sobre ou relacionados à Plataforma ou nenhuma
              parte dele.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              5.2. Quaisquer direitos não expressamente concedidos pelo Contrato
              de Prestação de Serviços são reservados.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              5.3. É expressamente vedado ao Cliente, o qual também não poderá
              permitir que Terceiros o façam:
              <ListItem type="lower-roman">
                <li>
                  efetuar modificações, acréscimos ou derivações da Plataforma,
                  por si ou através da contratação de Terceiros;
                </li>
                <li>
                  fazer engenharia reversa, descompilar ou desmontar a
                  Plataforma, ou tomar qualquer outra medida que possibilite o
                  acesso ao código fonte da mesma, sem o consentimento prévio e
                  por escrito da Conube; ou
                </li>
                <li>
                  copiar ou duplicar, total ou parcialmente, a Plataforma ou
                  seus recursos, ou usar de modo diverso do expressamente
                  estipulado no Contrato de Prestação de Serviços;
                </li>
                <li>
                  utilizar ou permitir a utilização da Plataforma para outra
                  finalidade que não seja o processamento de suas Informações ou
                  de pessoas jurídicas indicadas pelo Cliente no ato do
                  cadastramento, ou seu acesso por meios automatizados ou macros
                  (crawling, scraping, caching), incluindo, mas não se limitando
                  ao acesso, visualização ou obtenção de Informações dos
                  Serviços, do conteúdo disponibilizado pela Plataforma,
                  documentos e Informações dos demais Clientes dos Serviços ou
                  Terceiros afiliados à Conube, com exceção dos mecanismos de
                  busca padrão ou demais tecnologias aprovadas pela Conube.
                </li>
              </ListItem>
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">VI - Privacidade</Typography>
            <Typography className={classes.topic} variant="body1">
              6.1. A utilização dos Serviços está sujeita à Política de
              Privacidade, que está disponível no site:
              {' '}
              <a href="https://www.conube.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">conube.com.br/politica-de-privacidade</a>
              {' '}
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.2. A Contratação pressupõe a ciência e aceite da Política de
              Privacidade vigente, que se encontra no website:
              {' '}
              <a href="https://www.conube.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">conube.com.br/politica-de-privacidade</a>
              {' '}
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.3. Para fins da presente Cláusula, CONUBE e CONUBE TEC são
              denominadas como “CONTRATADAS”. As CONTRATADAS atuarão, por si,
              por seus representantes, prepostos, terceiros e/ou subcontratados,
              em conformidade com o previsto na Lei Geral de Proteção de Dados
              (“LGPD”), regulamentos e decretos relacionados ao tratamento de
              dados, bem como com as obrigações previstas nesta Cláusula durante
              qualquer Tratamento de Dado Pessoal realizado no contexto do
              presente Contrato. As CONTRATADAS comprometem-se a cumprir e a
              fazer cumprir todas as medidas necessárias para evitar que, dolosa
              ou culposamente, haja exposição e/ou divulgação dos Dados
              Pessoais, bem como qualquer outro tipo de Incidente de Segurança
              da Informação.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.4. Para fins da presente Cláusula, (i) “Dado(s) Pessoal(is)”
              significa qualquer informação relacionada a pessoa natural
              identificada ou identificável que seja Tratada em decorrência das
              obrigações das Partes no contexto deste Contrato, bem como
              informações que são compartilhadas com ou disponibilizadas a outra
              Parte nos termos deste Contrato; (ii) “Tratamento(s)”,
              “Controlador” e “Operador” possuem o significado atribuído pela
              LGPD; e (iii) “Incidente de Segurança da Informação” significa
              quaisquer acessos não autorizados aos Dados Pessoais Tratados em
              decorrência do presente Contrato, situações acidentais ou ilícitas
              de destruição, perda, alteração, comunicação ou qualquer forma de
              Tratamento inadequado ou ilícito.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.5. Em caso de subcontratação, as CONTRATADAS serão responsáveis
              civil e criminalmente por eventuais inobservâncias do
              subcontratado à legislação de proteção de dados, bem como
              quaisquer Incidentes de Segurança da Informação, mantendo o
              “CONTRATANTE” indene, inclusive de multas administrativas e
              demandas judiciais.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.6. Obrigam-se as CONTRATADAS, a informar o “CONTRATANTE”, por
              escrito, em até 48 (quarenta oito) horas a contar da ocorrência
              (ou suspeita de ocorrência) de qualquer Incidente de Segurança da
              Informação, informando a natureza dos Dados Pessoais afetados, os
              riscos relacionados, bem como as medidas de segurança para a
              proteção dos dados e mitigação dos prejuízos.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.7. AS CONTRATADAS Tratarão Dados Pessoais unicamente para o
              alcance dos fins delimitados neste Contrato, não podendo, em
              nenhum caso, utilizar esses Dados Pessoais para finalidade
              distinta, sob pena de rescisão imediata e assunção integral de
              quaisquer danos causados o “CONTRATANTE” e/ou a terceiros. Para
              consecução das finalidades previstas nesse Contrato, poderão ser
              Tratados os Dados Pessoais indicados na Ficha Cadastral do
              Empregado (anexa ao presente Contrato), bem como dados de contato
              com representantes do “CONTRATANTE”.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.8. Na hipótese de término do presente Contrato e, ausente
              qualquer base legal para tratamento dos Dados Pessoais, as
              CONTRATADAS comprometem-se a devolver os dados o “CONTRATANTE” e
              eliminar de seus registros e sistemas todos os Dados Pessoais a
              que teve acesso ou que recebeu de alguma forma em decorrência
              deste Contrato
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.9. As CONTRATADAS se obrigam a não utilizar, armazenar,
              transmitir, ceder, vender os Dados Pessoais e/ou informações
              obtidos em razão deste Contrato, ou dar tratamento diverso aos
              Dados Pessoais e informações que não seja o estrito cumprimento do
              objeto contratado.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.10. O “CONTRATANTE” poderá solicitar às CONTRATADAS, que se
              obrigam a cooperar e fornecer quaisquer documentos, explicações,
              evidências e informações que demonstrem a regularidade de uma
              atividade de Tratamento no âmbito do Contrato, em juízo e fora
              dele.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.11. Se quaisquer das CONTRATADAS receberem uma reclamação,
              consulta, ou solicitação de, ou em nome de, um titular de Dados
              Pessoais ou de autoridade reguladora ou outro órgão competente em
              relação ao Tratamento de Dados Pessoais decorrentes deste Contrato
              (incluindo, sem limitação, qualquer solicitação de acesso,
              retificação, exclusão, portabilidade ou restrição de tratamento de
              dados pessoais) de acordo com direitos previstos na legislação
              aplicável, deverão, imediatamente e em qualquer caso, dentro de 48
              (quarenta e oito) horas, notificar o “CONTRATANTE” por escrito
              sobre tal solicitação. Competirá o “CONTRATANTE” responder às
              solicitações recebidas, devendo as CONTRATADAS se limitarem a
              cooperar com o “CONTRATANTE”, devendo, portanto, se absterem de
              responderem às solicitações (exceto se contrariamente for acordado
              entre as Partes em caso específico)
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.12. As CONTRATADAS se obrigam a implementar medidas técnicas e
              administrativas que permitam o cumprimento de suas obrigações de
              proteção de Dados Pessoais, inclusive a efetuar a gestão de
              vulnerabilidades de suas ferramentas que sejam utilizadas no
              Tratamento de Dados Pessoais, realizando testes periódicos para
              identificação e imediata correção de eventuais vulnerabilidades
              que venham a ser identificadas.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.13. Caso quaisquer das CONTRATADAS ou subcontratados sujeitem os
              Dados Pessoais obtidos em decorrência deste Contrato à
              transferência internacional de dados, deverá tal parte adotar
              medidas legais para proteger os Dados Pessoais Tratados em
              decorrência da presente contratação com ao menos nível de proteção
              similar aos previstos na legislação brasileira e neste Contrato.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.14. Em caso de não cumprimento das obrigações desta Cláusula
              e/ou não cumprimento das leis e regulamentos de proteção de dados
              em relação aos Dados Pessoais, a parte que deu causa deverá
              indenizar, defender e isentar a outra e/ou suas afiliadas contra
              toda e qualquer responsabilidade, perda, reivindicação, dano,
              multa, penalidade, despesa (incluindo, sem limitação, multas,
              indenização por danos, custos dos esforços de reparação e
              honorários advocatícios e custos decorrentes de ou relacionados a
              qualquer ação, reivindicação ou alegação de terceiros - incluindo,
              sem limitação, qualquer autoridade reguladora ou governamental).
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.15. Caso a parte inocente venha a suportar qualquer dos
              procedimentos acima mencionados, fica resguardado o seu direito de
              regresso contra a outra, sem prejuízo do ressarcimento das
              despesas decorrentes do processo, além de outras medidas, como
              denunciação à lide, decorrentes de eventual violação de dados
              pessoais que lhe venha a ser imputada.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.16. As CONTRATADAS se obrigam a manter estrutura adequada e
              previsão de contingência específicas para o Tratamento de Dados
              Pessoais e informações obtidos em razão deste Contrato.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.17. O descumprimento de quaisquer obrigações dispostas nesta
              Cláusula VI pela CONUBE e/ou pela a CONUBE TEC será considerada
              como infração contratual, facultando-se o “CONTRATANTE” rescindir
              de pleno direito o presente instrumento, sem necessidade de
              notificação prévia, sem prejuízo de ressarcimento por eventuais
              prejuízos que tal infração venha a causar.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              6.18. A não observância de qualquer disposição das referidas leis
              implicarão em responsabilidade exclusiva do infrator
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">
              VII - Declarações e obrigações do cliente
            </Typography>
            <Typography className={classes.topic} variant="body1">
              7.1. Pela Contratação, o Cliente (e/ou seus sucessores,
              colaboradores, representantes, prepostos) declarará e se obrigará,
              para todos os fins, em caráter irrevogável e irretratável, sob
              pena de responsabilidade civil e criminal, conforme aplicável,
              que:
              <ListItem type="lower-roman">
                <li>
                  pagará integralmente o valor da Remuneração, e adiantará ou
                  reembolsará, conforme acordado entre as Partes, mediante
                  apresentação dos respectivos comprovantes, todos os valores
                  gastos com materiais para execução dos Serviços, tais como
                  livros, correios, carimbos, pastas de arquivos, cds, cópias
                  etc. que poderão ser antecipados pela Conube;
                </li>
                <li>
                  ficará a CONTRATANTE obrigada a confirmar o seu faturamento do
                  mês anterior até o dia 3 do mês subsequente. Dessa forma, a
                  CONTRATANTE deverá proativamente entrar em contato para
                  informar a CONTRATADA, via e-mail ou mediante as ferramentas
                  indicadas pela CONTRATADA na plataforma da LOCADORA, os
                  valores adicionais faturados até a supracitada data, sob pena
                  da CONTRATADA realizar a apuração incorreta para menor dos
                  valores devidos de impostos e da CONTRATANTE ser obrigada a
                  arcar integralmente com o valor de eventuais multas e juros
                  por ocasião dos próximos pagamentos junto aos órgãos públicos.
                </li>
                <li>
                  utilizará os Serviços apenas para fins particulares, sem:
                  <ListItem type="lower-alpha">
                    <li>
                      ameaçar, perseguir, perturbar ou intimidar outra pessoa de
                      qualquer forma, incluindo a restrição ou inibição do uso
                      da Plataforma;
                    </li>
                    <li>
                      personificar qualquer pessoa (incluindo a equipe da Conube
                      ou outros Clientes), ou atestar falsamente afiliação ou
                      representação de qualquer pessoa ou empresa, através do
                      uso de endereços de e-mail similares, apelidos, ou a
                      criação de contas falsas ou qualquer outro método ou
                      procedimento;
                    </li>
                    <li>
                      disfarçar a origem de quaisquer Informações que sejam
                      transmitidas a Terceiros;
                    </li>
                    <li>
                      coletar ou armazenar Informações de outros Clientes;
                    </li>
                    <li>
                      publicar, enviar ou transmitir qualquer arquivo que
                      contenha vírus, worms, cavalos de Tróia ou qualquer outro
                      programa que possa contaminar, destruir ou interferir no
                      pleno funcionamento da Plataforma;
                    </li>
                  </ListItem>
                </li>
                <li>
                  será o único responsável pela obtenção, pagamento e manutenção
                  de todos os serviços de telefonia, acesso à internet, plano de
                  dados, tarifas e/ou outras taxas, mensalidades e custos
                  associados ao seu acesso e uso dos Serviços, bem como pelo
                  software, hardware de computador e outros equipamentos
                  necessários para o uso e acesso aos Serviços e à Plataforma;
                </li>
                <li>
                  está ciente que a Conube não garante que as funções contidas
                  na Plataforma atenderão às suas necessidades, que a operação
                  da Plataforma será ininterrupta ou livre de erros, que
                  qualquer dos Serviços continuará disponível, que os defeitos
                  da Plataforma serão corrigidos ou que a Plataforma será
                  compatível ou funcionará com qualquer aplicação ou serviço de
                  Terceiros;
                </li>
                <li>
                  está ciente sobre o conteúdo na Lei n° 9.613/1998, que dispõe
                  sobre os crimes de “lavagem” ou ocultação de bens, direitos e
                  valores;
                </li>
                <li>
                  está ciente de que todos os Serviços serão realizados através
                  da Plataforma, mediante a utilização do Cadastro realizado, e
                  a comunicação entre o Cliente e a Coube ocorrerá através de
                  e-mail fornecido no Cadastro e os e-mails oportunamente
                  indicados pela Conube. A indicação do e-mail do Cliente que
                  acessará a Plataforma e se comunicará com a Conube é de
                  responsabilidade do Cliente. A equipe da Conube, por questão
                  de confidencialidade de dados, não está autorizada a realizar
                  comunicações com e-mails não cadastrados na Plataforma;
                </li>
                <li>
                  atenderá a todos os requisitos legais para abertura de empresa
                  no que diz respeito às atividades empresariais,
                  comprometendo-se a atender a todas as exigências sempre que
                  necessário e solicitado por quaisquer órgãos públicos, bem
                  como a ter um sistema de escrituração contábil;
                </li>
                <li>
                  cumprirá todos os requisitos da Plataforma e manterá
                  Informações corretas, completas e atualizadas, ciente de que
                  está proibido por determinação legal e/ou contratual de passar
                  Informações falsas à Conube. A Conube não se responsabiliza
                  por erros, omissões e/ou inconsistências no fornecimento das
                  Informações pelo Cliente, nem pelo cadastramento, permissões,
                  senhas e modo de utilização. As Informações enviadas pelo
                  Cliente são de inteira responsabilidade do Cliente, não sendo,
                  portanto, revisadas em momento algum;
                </li>
                <li>
                  acessará regularmente a Plataforma para verificação das
                  informações e comunicações, bem como cumprirá com todos os
                  requisitos dos termos assinados junto à Plataforma. O não
                  atendimento das orientações e instruções não são de
                  responsabilidade da Conube;
                </li>
                <li>
                  informará à Conube sempre que houver qualquer alteração que
                  possa impedir, limitar e/ou prejudicar o acesso às Informações
                  necessárias para a execução das funcionalidades ofertadas pela
                  Conube, bem como caso acredite que seu login e senha de acesso
                  aos Serviços tenham sido apropriados por outrem e/ou sejam de
                  conhecimento de outras pessoas, por qualquer razão, sem
                  prejuízo da alteração de senha imediata por meio da
                  Plataforma. O Cliente concorda que a Conube não será
                  responsabilizada por qualquer perda ou dano que ocorra a
                  partir do não cumprimento desta obrigação;
                </li>
                <li>
                  está ciente que a Plataforma e quaisquer modificações,
                  acréscimos, atualizações, customizações e novas versões são
                  protegidos pela legislação que protege direitos autorais e de
                  propriedade intelectual de modo que a prestação dos Serviços
                  pela Conube não transfere quaisquer direitos de utilização
                  comercial ao Cliente, e não constitui uma concessão ou
                  renúncia de quaisquer direitos dos titulares ao direito
                  autoral. O Cliente deverá limitar o acesso à Plataforma
                  àqueles cujo acesso seja necessário para a utilização dos
                  Serviços;
                </li>
                <li>
                  está ciente de que quando houver troca de contador, caso não
                  sejam entregues informações e/ou documentos necessários à
                  prestação dos Serviços, a Conube não se responsabilizará pela
                  não entrega ou eventuais multas fiscais aplicáveis;
                </li>
                <li>
                  coletará eventuais documentos físicos ou digitais com o
                  contador responsável pelas competências anteriores e enviará
                  em formato digital via a plataforma Trocar de Contador no
                  prazo de até 60 (sessenta) dias contados desta data. A falta
                  do envio dos documentos constitui inadimplemento contratual, e
                  poderá ocasionar a rescisão do Contrato de Prestação de
                  Serviços, sendo aplicável multa no valor da Remuneração
                  referente ao primeiro mês do Contrato de Prestação de
                  Serviços;
                </li>
                <li>
                  enviará, no prazo de 5 (cinco dias) contados desta data, todos
                  as Informações e documentos necessários para o início da
                  prestação dos Serviços, ciente de que a responsabilidade
                  técnica terá início somente após o envio dos documentos de
                  acordo com orientação da Conube. A falta do envio dos
                  documentos constitui inadimplemento contratual;
                </li>
                <li>
                  atenderá a toda solicitação da Conube acerca de envio de
                  Informações necessárias para a prestação dos Serviços,
                  inclusive pagamentos recebidos, impostos, tributos pagos,
                  notas fiscais emitidas e outros. No caso de Serviços Mensais,
                  deverá atentar aos prazos indicados da cláusula 3.20. Toda
                  documentação deverá ser digitalizada e encaminhada pela
                  Plataforma;
                </li>
                <li>
                  está ciente de que a Conube não prestará os Serviços se o
                  Cliente não enviar a documentação solicitada nos prazos
                  indicados;
                </li>
                <li>
                  está ciente que a Conube não se responsabiliza por qualquer
                  dano, prejuízo incidental, especial, indireto ou consequente,
                  incluindo, sem limitação, prejuízos por perda de lucro,
                  corrupção ou perda de dados, falha de transmissão ou recepção
                  de dados, não continuidade do negócio ou qualquer outro
                  prejuízo sofrido pelo Cliente em razão de falhas no sistema ou
                  nos servidores utilizados, seja por defeitos, ataques, vírus,
                  interferências, atividades de hackers ou outra intrusão de
                  segurança, nem com relação aos danos causados por downloads,
                  ou ao uso dos Serviços ou da Plataforma, nem relacionadas à
                  inabilidade, incapacidade e/ou impossibilidade de usá-los,
                  incluindo hipóteses de negligência; Caso não seja possível
                  remeter os documentos pela Plataforma, estes deverão ser
                  remetidos para e-mail oportunamente indicado pela Conube;
                </li>
                <li>
                  Nossos trabalhos não preveem a execução de atividades com o
                  propósito gerencial ou destinada a tomada de decisões em nome
                  da administração da CONTRATANTE e nem iremos fazê-lo. Além
                  disso, nossos trabalhos não são planejados e/ou realizados com
                  o objetivo de revelar fraudes ou representações incorretas
                  efetuadas pela administração e, portanto, não aceitamos
                  responsabilidade pela identificação de fraudes (praticadas
                  pela administração ou por terceiros) nem pela identificação de
                  representações incorretas fornecidas pela administração da
                  instituição;
                </li>
                <li>
                  A indisponibilidade da Plataforma não exclui a
                  responsabilidade do Cliente de enviar os documentos, devendo
                  os mesmos serem remetidos por e-mail (contador@conube.com.br
                  para o plano online, private@conube.com.br para o plano
                  private e corporate@conube.com.br para o plano corporate). A
                  utilização do e-mail será apenas em casos excepcionais de
                  indisponibilidade da Plataforma, e previamente autorizado pela
                  equipe da Conube;
                </li>
                <li>
                  fornecerá anualmente, antes do encerramento de cada exercício
                  social, Carta de Responsabilidade da Administração, conforme
                  alínea “j” do artigo 2º da Resolução n.º 1.590/20 do Conselho
                  Federal de Contabilidade, a qual será disponibilizada junto à
                  Plataforma. Os termos indicados neste documento seguem as
                  condutas e normas contábeis vigentes no país, e a sua emissão
                  está vinculada a emissão da via final do balanço e
                  demonstração de resultado anual;
                </li>
                <li>
                  obterá certificado digital e-CNPJ, modelo A1, podendo ter de
                  se deslocar até uma unidade certificadora próxima, caso o
                  atendimento por vídeo conferência não esteja disponível, para
                  possibilitar a prestação dos Serviços pela Conube. A falta de
                  certificado impossibilita a prestação dos Serviços e,
                  consequentemente, impede a entrega de declarações. Dessa
                  forma, as multas geradas pela ausência de entrega de
                  declarações em razão da falta de certificado digital não são
                  de responsabilidade da Conube e não gera ao Cliente o direito
                  à restituição/indenização de qualquer valor. O certificado
                  deverá ser mantido vigente na Plataforma;
                </li>
                <li>
                  atenderá rigorosamente, em qualquer hipótese, a legislação,
                  especialmente no que se refere às suas obrigações tributárias,
                  fiscais, trabalhistas e previdenciárias, seja de natureza
                  principal ou acessória, bem como quaisquer outras, entendendo
                  que os Serviços prestados constituem obrigação de meio e não
                  de resultado, não dispensando, portanto, a correta
                  apresentação das Informações necessárias pelo Cliente com base
                  na legislação em vigor, bem como que a Conube não se
                  responsabiliza por qualquer violação, ato ilícito ou infrações
                  legais cometidas pelo Cliente, nem pelos danos ou prejuízos
                  decorrentes de decisões administrativas, gerenciais ou
                  comerciais tomadas com base nas Informações fornecidas pelo
                  Cliente;
                </li>
                <li>
                  está ciente que o serviço de portador para realização das
                  diligências junto a cartórios e órgãos públicos não está
                  incluso no Contrato de Prestação de Serviços e deverá ser
                  realizado pelo Cliente;
                </li>
                <li>
                  está ciente de que a Conube se isenta de quaisquer garantias
                  sobre informações ou conselhos obtidos através da Plataforma,
                  e quaisquer garantias de Terceiros por serviços ou bens
                  recebidos através ou anunciados na Plataforma ou recebidos
                  através de links exibidos na Plataforma, bem como por qualquer
                  informação ou conselho recebido através de quaisquer links
                  exibidos na Plataforma;
                </li>
                <li>
                  está ciente que a disponibilização dos Serviços deve atender
                  aos critérios estabelecidos pela Conube, a qual pode definir
                  quais pessoas serão atendidas de acordo com a característica
                  de obrigações a serem declaradas;
                </li>
                <li>
                  está ciente que a Conube também não se responsabiliza por
                  qualquer ação considerada como responsabilidade do contador do
                  Cliente quando este decidir contratar profissional Terceiro
                  para consultorias que possam influenciar nas Informações
                  utilizadas na prestação dos Serviços;
                </li>
                <li>
                  stá ciente que a Conube não se responsabiliza pela
                  disponibilidade dos sistemas de Terceiros, sejam órgãos
                  públicos ou privados, fazendários ou não, cabendo a estes a
                  responsabilidade sobre eventuais omissões ou atrasos no
                  cumprimento de obrigações;
                </li>
                <li>
                  está ciente que a Conube não se responsabiliza por problemas
                  definidos como “caso fortuito” ou “força maior”, contemplados
                  pelo artigo 393 do Código Civil Brasileiro;
                </li>
                <li>
                  está ciente que a Conube não se responsabiliza por eventuais
                  problemas oriundos de ações de Terceiros que possam interferir
                  na qualidade da prestação dos Serviços, nem por danos causados
                  a Terceiros em razão de conteúdo gerado pelo Cliente através
                  da Plataforma;
                </li>
                <li>
                  está ciente de que sob nenhuma circunstância a
                  responsabilidade integral da Conube com relação ao Cliente por
                  todos os danos excederá a quantia correspondente ao valor pago
                  pelo Cliente à Conube;
                </li>
                <li>
                  leu, compreende e aceita o disposto na Política de Privacidade
                  (
                  <a href="https://www.conube.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">conube.com.br/politica-de-privacidade.</a>
                  {' '}
                  ) quanto à confidencialidade e
                  ambientes de proteção das Informações, dados pessoais e
                  registros de acesso da Conube, consentindo livre e
                  expressamente às ações de coleta, uso, armazenamento e
                  tratamento das referidas Informações e dados;
                </li>
                <li>
                  é de responsabilidade do Cliente os juros e a correção
                  monetária de qualquer natureza de recomposição e remuneração
                  do valor não recolhido pelo Cliente ou recolhido em mora.
                </li>
              </ListItem>
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">
              VIII - Declarações e obrigações da Conube
            </Typography>
            <Typography className={classes.topic} variant="body1">
              8.1. A partir da Contratação, a Conube (e/ou seus sucessores,
              colaboradores, representantes, prepostos) declarará e se obrigará,
              para todos os fins, em caráter irrevogável e irretratável, sob
              pena de responsabilidade civil e criminal, conforme aplicável,
              que:
              <ListItem type="lower-roman">
                <li>
                  prestará os Serviços com todo zelo, diligência e honestidade,
                  observada a legislação vigente, de forma ininterrupta,
                  resguardando os interesses do Cliente, sem prejuízo da
                  dignidade, qualidade técnica sigilo e independência
                  profissionais, sujeitando-se, ainda, às normas do Código de
                  Ética Profissional do Contabilista, aprovado pela Resolução n°
                  80396 do Conselho Federal de Contabilidade;
                </li>
                <li>
                  manterá ativo seu registro profissional, no Conselho Federal
                  de Contabilidade, durante toda a vigência do Contrato de
                  Prestação de Serviços;
                </li>
                <li>
                  fornecerá acesso à Plataforma pelo prazo de vigência do
                  Contrato de Prestação de Serviços, a qual deverá funcionar
                  regularmente, se respeitadas as condições de uso definidas no
                  presente Termos de Uso e no Contrato de Prestação de Serviços;
                </li>
                <li>
                  manterá atualizadas as funções existentes na Plataforma com
                  relação às variáveis normalmente alteradas pela legislação
                  federal. A interpretação legal das normas editadas pelo
                  governo será efetuada com base no entendimento majoritário dos
                  prestadores dos Serviços, doutrinadores e jurisprudência
                  pátria. Ficam excluídas da manutenção as alterações na
                  Plataforma originárias de obrigações assumidas pelo Cliente
                  junto a sindicatos ou associações, tais como convenções
                  coletivas de trabalho e outras, bem como necessidades
                  específicas de certos segmentos, além do atendimento de
                  legislações municipais e/ou estaduais;
                </li>
                <li>
                  alterará as especificações e/ou características da Plataforma
                  para a melhoria e/ou correções de erros, de acordo com o plano
                  de Serviços definido pela Conube, podendo a seu critério
                  substituir a cópia dos programas com falhas por cópias
                  corrigidas;
                </li>
                <li>
                  fornecerá manutenção e suporte apenas para Plataforma
                  utilizada, bem como dúvidas direcionadas à prestação dos
                  Serviços;
                </li>
                <li>
                  se responsabiliza por eventuais multas decorrentes da entrega
                  de declarações fora do prazo legal e pagamento de impostos
                  atrasados, que forem decorrentes da não execução dos Serviços
                  por parte da Conube, sendo que esta ficará isenta em caso de
                  atraso de documentos ou declarações a serem fornecidas pelo
                  Cliente;
                </li>
              </ListItem>
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">
              IX - Declarações e obrigações adicionais das partes
            </Typography>
            <Typography className={classes.topic} variant="body1">
              9.1. Pela Contratação as Partes assumirão o compromisso de manter
              comportamento compatível com as normas de disciplina e ética
              profissional, e de segurança estabelecidos pelo Direito
              brasileiro, incluindo a Lei Anticorrupção Brasileira (Lei nº
              12.846/2013). É vedado às Partes beneficiar-se de quaisquer
              favores ou vantagens de distribuidores, prestadores ou
              fornecedores. É vedado ainda às Partes dar, oferecer, pagar,
              prometer pagar, ou autorizar o pagamento de, direta ou
              indiretamente, qualquer dinheiro ou qualquer bem de valor a
              qualquer autoridade governamental, funcionários públicos, agentes,
              particulares, parceiros, com a finalidade de influenciar ou
              direcionar qualquer ato ou decisão de forma ilegal ou antiética,
              ou para assegurar qualquer vantagem indevida para si ou Terceiro.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              9.2. Os Serviços serão prestados pela Conube em favor do Cliente,
              sem qualquer subordinação hierárquica, relação ou vínculo
              empregatício, e com total independência técnica, com estrita
              observância da boa técnica, mediante o emprego de métodos e
              processos que a Conube julgar convenientes, resguardados de
              sigilo.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              9.3. As partes se comprometem, durante o prazo de vigência desse
              contrato, e por mais 1 (um) ano após o seu término, a não admitir,
              em seu respectivo quadro funcional, nem contratar como prestador
              de serviços, direta ou indiretamente, sem o prévio e expresso
              consentimento por escrito da outra parte, qualquer dos empregados
              dos quadros desta última.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              9.4. A Conube poderá prestar os Serviços, por si, mediante emprego
              de mão de obra própria e ainda por meio de contratação de
              Terceiros, sendo certo, entretanto, que não se estabelecerá, em
              hipótese alguma, vínculo de qualquer natureza entre o Cliente e
              tais profissionais, sendo a Conube a única responsável por todos e
              quaisquer efeitos de tais contratações, em especial, mas não
              exclusivamente, no tocante à remuneração que tais profissionais
              façam jus.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              9.5. Pela Contratação, as Partes se isentarão mutuamente de
              qualquer responsabilidade que venha a lhes ser imputada em
              decorrência de ações, reclamações ou reivindicações de natureza
              trabalhista, previdenciária ou securitária, interpostas por
              funcionários ou empregados, comprometendo-se, ainda, a
              indenizar-se de qualquer despesa que venham a incorrer em virtude
              de tais ações, reclamações ou reivindicações.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              9.6. Os Serviços serão prestados pela Conube em favor do Cliente
              com total independência técnica, mediante a utilização de métodos
              que a Conube julgar conveniente, resguardados de sigilo.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              9.7. Os tributos de qualquer natureza, que sejam devidos em
              decorrência direta ou indireta da execução dos Serviços, serão de
              responsabilidade exclusiva do contribuinte, assim definido na
              norma tributária, sem direito a reembolso.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              9.8. Se, na vigência do Contrato de Prestação de Serviços, forem
              criados novos tributos ou modificadas as alíquotas dos atualmente
              exigidos, os descontos de carga tributária determinados no
              Contrato de Prestação de Serviços obedecerão àquela vigente na
              época do cálculo do desconto, sendo certo que o seu aumento e/ou
              diminuição não ensejarão a revisão dos preços e valores
              estabelecidos.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">X - Prazo de vigência</Typography>

            <Typography variant="h4">A) Para serviço de abertura</Typography>

            <Typography className={classes.topic} variant="body1">
              10.1. A Contratação dos Serviços de Abertura se inicia com o
              aceite do Aceite Inicial e tem vigência de 180 (cento e oitenta)
              dias. Caso o Cliente não conclua o processo até o final desse
              período, o Contrato de Prestação de Serviços será automaticamente
              cancelado. Não havendo ressarcimento dos valores pagos.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.2. O tempo de prestação do Serviço de Abertura pode variar
              conforme a localidade e disponibilidade do órgão público. O início
              da prestação pela Conube ocorre somente quando da confirmação do
              pagamento e a entrega dos documentos necessários e dos formulários
              devidamente assinados.
            </Typography>
            <Typography variant="h4">B) Para serviço de abertura</Typography>
            <Typography className={classes.topic} variant="body1">
              10.3. O Contrato de Prestação de Serviços vigorará a partir do
              início da responsabilidade da Conube, pelo prazo de 12 (doze)
              meses, e não se interrompe, ainda que o Cliente fique inerte ao
              fornecimento de Informações ou protocolos a serem realizados nos
              órgãos públicos.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.4. O Contrato de Prestação de Serviços vigorará a partir da
              obtenção de um CNPJ para o Cliente, momento em que passa a existir
              legalmente, e momento em que se inicia a responsabilidade da
              Conube, pelo prazo de 12 (doze) meses, e não se interrompe, ainda
              que o Cliente fique inerte ao fornecimento de Informações ou
              protocolos a serem realizados nos órgãos públicos.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.5. Ao final dos 12 (doze) meses contratados, o Contrato de
              Prestação de Serviços poderá ser prorrogado por igual período,
              quantas vezes forem necessárias, sem, contudo, se tornar um
              Contrato de Prestação de Serviços por prazo indeterminado.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.6. A rescisão deste Contrato durante os primeiros 12 (doze)
              meses de vigência sujeitará à CONTRATANTE ao pagamento de multa no
              valor de 40% (quarenta por cento) sobre o valor equivalente aos
              meses restantes de vigência do contrato. O contrato poderá ser
              rescindido a qualquer momento pelas partes, mediante comunicação
              prévia de 90 (noventa) dias.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.7. A cada prorrogação o Cliente deverá celebrar aditamento ou
              novo Contrato de Prestação de Serviços, de modo que alterações
              recentes aos Termos de Uso e à Política de Privacidade serão
              aplicadas, bem como eventuais alterações de condições contratuais,
              que deverão ser lidas e aceitas pelo Cliente antes da celebração
              de qualquer nova contratação.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.8. A Conube se reserva o direito de alterar ou modificar os
              Termos de Uso e a Política de Privacidade e a Tabela de Preços, a
              qualquer momento, de modo a mantê-los de acordo com a legislação
              vigente. Qualquer alteração ou modificação entrará em vigor
              imediatamente após a publicação no website, e poderá interferir na
              forma da prestação dos Serviços contratados pelo Contrato de
              Prestação de Serviços, desde que não altere a prestação final. O
              Cliente será responsável por analisar periodicamente a mais
              atualizada versão dos Termos de Uso e da Política de Privacidade.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.9. Caso as alterações aos Termos de Uso impliquem em alterações
              de condições contratuais, as Partes deverão entrar em acordo de
              modo a (i) manter os termos do Contrato de Prestação de Serviços,
              opção que deverá ser formalizada por escrito; ou (ii) celebrar
              aditamento contratual ao Contrato de Prestação de Serviços, de
              modo a contemplar as alterações.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.10. O uso continuado dos Serviços e da Plataforma constitui a
              aceitação de quaisquer alterações ou modificações feitas,
              independentemente de qualquer realização de aditamento ou aceite.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.11. As alterações legislativas aplicáveis ao objeto do Contrato
              de Prestação de Serviços prevalecerão, independentemente de
              aditamento contratual, devendo as Partes se adequar aos
              dispositivos previstos ordenamento jurídico.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              10.12. Ocorrendo a transferência dos Serviços para outro
              profissional contábil, o Cliente deverá informar à Conube, por
              escrito, o nome, endereço, nome do responsável e número da
              inscrição no Conselho Regional de Contabilidade, sem o que não
              será possível à Conube cumprir as formalidades fiscais e
              ético-profissionais, inclusive a transmissão de dados e
              Informações necessárias à continuidade dos serviços, em relação às
              quais, diante da eventual inércia do Cliente, estará desobrigada
              de cumprimento.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">
              X1 - Inadimplemento, penalidades e recisão
            </Typography>

            <Typography className={classes.topic} variant="body1">
              11.1. Em até 7 (sete) dias contados da Contratação, em caso de
              desistência, o Cliente poderá solicitar o cancelamento. Neste
              caso, o reembolso da Remuneração, excluídas as taxas já pagas pela
              Conube, será realizado pela Conube através de estorno no cartão de
              crédito no qual a compra foi efetuada, ou depósito em conta
              corrente a ser oportunamente indicada, em até 60 (sessenta) dias
              após a solicitação.
              <ListItem type="lower-roman">
                <li>
                  Após 7 (sete) dias contados da Contratação, em caso de
                  desistência, o Cliente poderá solicitar o cancelamento. Neste
                  caso, somente será reembolsado no valor de 50% (cinquenta por
                  cento) da Remuneração, excluídas as taxas já pagas pela
                  Conube, através de estorno no cartão de crédito no qual a
                  compra foi efetuada, ou depósito em conta corrente a ser
                  oportunamente indicada, em até 60 (sessenta) dias após a
                  solicitação.
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.2. O inadimplemento das Partes quanto a quaisquer obrigações
              instituídas por força do Contrato de Prestação de Serviços, ficará
              caracterizado mediante prévia notificação escrita, em que se
              oferecerá à Parte inadimplente prazo de 15 (quinze) dias para o
              cumprimento da obrigação em falta.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.3. Decorrido o prazo da notificação sem que a obrigação em
              falta seja cumprida, à Parte inocente será lícito considerar
              rescindido o Contrato de Prestação de Serviços de pleno direito,
              com a apuração das perdas e danos que o inadimplemento lhe
              acarretou, o que incluirá pagamento ou restituição dos valores
              atualizados monetariamente pelo IGP-M, ou outro índice que vier a
              substituí-lo em caso de sua extinção, acrescidos de juros
              moratórios de 1% ao mês, honorários advocatícios e custos dos
              respectivos processos, sem prejuízo de multa penal de caráter não
              compensatório, calculada em 10% (dez por cento) sobre o valor da
              Remuneração.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.4. Quando, justificadamente, e por motivos alheios à sua
              vontade, não for possível à Parte culpada sanar a irregularidade
              dentro do prazo indicado, este poderá ser prorrogado pela Parte
              inocente a seu exclusivo critério.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.5. A notificação, ainda que atendida com a correção da
              irregularidade, não eximirá a Parte culpada do pagamento das
              multas ou do cumprimento das penalidades previstas no Contrato de
              Prestação de Serviços ou na legislação vigente, salvo na hipótese
              de quitação expressa e escrita da Parte inocente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.6. A rescisão do Contrato de Prestação de Serviços não
              prejudicará o direito de Parte inocente ressarcir-se dos danos e
              prejuízos que haja sofrido, tampouco de executar as demais
              penalidades e consequências previstas no Contrato de Prestação de
              Serviços, sejam de caráter genérico ou específico, bem como da
              legislação vigente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.7. O Cliente poderá rescindir imotivadamente o Contrato de
              Prestação de Serviços, com a consequente aplicação de multa
              equivalente ao valor total da Remuneração, descontados os valores
              já pagos, atualizados monetariamente pelo IGP-M, ou outro índice
              que vier a substituí-lo em caso de sua extinção, acrescidos de
              juros moratórios de 1% ao mês, honorários advocatícios e custos
              dos respectivos processos, sem prejuízo de multa penal de caráter
              não compensatório, calculada em 10% (dez por cento) sobre o valor
              total da Remuneração.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.8. A Conube poderá rescindir imotivadamente o Contrato de
              Prestação de Serviços, a qualquer tempo, desde que comunique ao
              Cliente, com antecedência mínima de 15 (quinze) dias, devendo
              neste caso restituir os valores já pagos referentes ao valor da
              Remuneração, se existentes.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.9. O Contrato de Prestação de Serviços será considerado
              rescindido, de pleno direito, independentemente de quaisquer
              notificações ou avisos e sem prejuízo das penalidades e/ou
              consequências genéricas ou específicas, previstas no Contrato de
              Prestação de Serviços e na legislação vigente, quando aplicável,
              nas seguintes hipóteses:
              <ListItem type="lower-roman">
                <li>
                  Recuperação judicial ou extrajudicial, falência ou insolvência
                  de qualquer uma das Partes;
                </li>
                <li>
                  Recusa em sanar irregularidade após competente notificação;
                </li>
                <li>
                  Reincidência de irregularidade sanada após competente
                  notificação; e
                </li>
                <li>Atraso no pagamento da Remuneração por 3 (três) meses;</li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.10. A Conube poderá rescindir imotivadamente o Contrato de
              Prestação de Serviços, a qualquer tempo, desde que comunique ao
              Cliente, com antecedência mínima de 15 (quinze) dias, devendo
              neste caso restituir os valores já pagos referentes ao valor da
              Remuneração, se existentes.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.11. Suspenso o acesso do Cliente à Plataforma, a Conube manterá
              as Informações armazenadas na Plataforma pelo período de 90
              (noventa) dias, contados da suspensão de acesso. Durante este
              período, a Conube tornará as Informações disponíveis para serem
              extraídas da Plataforma.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.12. Passados 90 (noventa) dias da suspensão do acesso do
              Cliente à Plataforma, todas as Informações em poder da Conube,
              poderão ser excluídas permanentemente do banco de dados da Conube,
              independentemente de terem sido extraídas ou não pelo Cliente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              11.13. Não obstante o disposto acima, as Informações referentes à
              data e hora de acesso e ao endereço de protocolo de internet
              utilizado pelo Cliente para acessar a Plataforma permanecerão
              armazenadas pela Conube por 6 (meses) a contar da data de cada
              acesso realizado, independentemente do término da relação jurídica
              e comercial entre a Conube e o Cliente, em cumprimento ao disposto
              no Artigo 15 da Lei nº 12.965/2014, podendo ser armazenados por um
              período maior mediante ordem judicial.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <center>
              <Typography variant="h3">
                Cláusula XII
                {' '}
                <br />
                {' '}
                execução específica
              </Typography>
            </center>
            <Typography className={classes.topic} variant="body1">
              12.1. As obrigações de fazer e de pagar quantia certa decorrentes
              do Contrato de Prestação de Serviços comportarão execução
              específica nos termos dos artigos 497 e 815 do Código de Processo
              Civildo Código de Processo Civil Brasileiro.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              12.2. As Partes reconhecem que o Contrato de Prestação de Serviços
              constituirá título executivo extrajudicial para todos os fins e
              efeitos.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">XIII - Considerações finais</Typography>
            <Typography className={classes.topic} variant="body1">
              13.1. Com o Aceite Inicial, e posterior celebração do Contrato de
              Prestação de Serviços, os Clientes estarão de acordo com todas as
              disposições constantes nestes Termos de Uso, os quais estão
              refletidos no Contrato de Prestação de Serviços, sendo que os
              casos omissos e as dúvidas porventura suscitadas serão
              solucionadas pela Conube.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.2. O Contrato de Prestação de Serviços, na data de sua
              celebração, constituirá o inteiro entendimento das Partes quanto à
              negociação e registrará fielmente as negociações anteriormente
              mantidas e as suas respectivas intenções, substituindo
              integralmente quaisquer outros documentos ou memorandos de
              qualquer espécie anteriormente trocados ou assinados, e, para
              todos os efeitos, será o único contrato que governará as relações
              entre as Partes quanto ao contratado.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.3. Sujeitos aos termos e às condições do Contrato de Prestação
              de Serviços, cada uma das Partes se obrigará a envidar seus
              melhores esforços para tomar, ou fazer com que sejam tomadas,
              todas as medidas apropriadas e praticar, ou fazer com que sejam
              praticados, todos os atos razoavelmente necessários ou
              convenientes, nos termos das leis aplicáveis, para fiel
              implementação das disposições do Contrato de Prestação de
              Serviços, e para consumar e tornar efetivas as operações lá
              estipuladas.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.4. Cada uma das Partes firmará o Contrato de Prestação de
              Serviços, declarando que (a) está ciente das obrigações assumidas
              e da legislação aplicável para sua regulação; (b) possui
              capacidade para compreender plenamente todos os termos e
              condições; e (c) não está sujeita a qualquer situação excepcional
              de necessidade econômica ou financeira, assumindo integralmente os
              ônus e riscos decorrentes da Contratação, incluindo, sem
              limitação, da obrigação de indenizar pactuada naquele Contrato de
              Prestação de Serviços.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.5. Após celebração do Contrato de Prestação de Serviços, ficará
              proibida a cessão ou transferência, no todo ou em parte, dos
              direitos e obrigações das Partes estabelecidas, salvo mediante
              autorização prévia e expressa da outra Parte, sendo certo que a
              autorização não eximirá as Partes da responsabilidade total pelo
              cumprimento de todos os termos e condições.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.6. As Partes, quando da celebração de Contrato de Prestação de
              Serviços, na melhor forma de direito, reconhecerão que, exceto se
              expressamente previsto: (a) o não exercício, a concessão de prazo,
              a tolerância, ou o atraso em exercer qualquer direito que lhes
              seja assegurado, pelo Contrato de Prestação de Serviços e/ou pela
              lei, não constituirá novação ou renúncia desses direitos, nem
              prejudicará o seu eventual exercício; (b) o exercício singular ou
              parcial desses direitos não impedirá o posterior exercício do
              restante desses direitos, ou o exercício de qualquer outro
              direito; (c) a renúncia de qualquer desses direitos não será
              válida, a menos que seja concedida por escrito; e (d) a renúncia
              de um direito deverá ser interpretada restritivamente, e não será
              considerada como renúncia de qualquer outro direito conferido pelo
              Contrato de Prestação de Serviços.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.7. O Contrato de Prestação de Serviços será celebrado em
              caráter irrevogável, irretratável e irreversível, obrigando não
              somente as Partes, mas seus herdeiros e sucessores, a qualquer
              título.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.8. Todas as notificações e avisos relacionados com o Contrato
              de Prestação de Serviços deverão ser feitos todos por escrito, por
              meio de carta registrada ou protocolada, telegrama ou e-mail,
              todos com comprovação de recebimento, por cartório de títulos e
              documentos ou por via judicial, dirigidos e/ou entregues às Partes
              nos endereços constantes do preâmbulo do Contrato de Prestação de
              Serviços ou em outro endereço que uma das Partes venha a comunicar
              a outra, a qualquer tempo, na vigência daquele.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.9. O Contrato de Prestação de Serviços será disciplinado e
              regido pelas Leis da República Federativa do Brasil, em especial,
              pelas disposições contidas nos artigos 593 e seguintes do Código
              Civil Brasileiro.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              13.10. Ficará eleito pelas Partes o foro da Comarca de São Paulo,
              Estado de São Paulo, para dirimir quaisquer dúvidas, com expressa
              renúncia a qualquer outro que se apresente, por mais privilegiado
              que seja.
            </Typography>
          </div>
        </div>
      </ContentWrap>
    </div>
  );
}
