import React from 'react';
import Main from '../components/pages/Main';
import TermsAndConditions from '../components/pages/terms-and-conditions';

const TermosECondicoes = () => (
  <Main page="termos-e-condicoes">
    <TermsAndConditions />
  </Main>
);

export default TermosECondicoes;
